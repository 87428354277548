import _ from "lodash";

export function getMessage(msgType) {
  if (typeof protobuf === "undefined") return {};
  return _.get(protobuf.roots, `default.hk.com.easyview.protobuf.bean.${msgType}`, {});
}

// 请求体message
export const PBMessageRequest = getMessage("Request");
// 请求体公共参数
export const PBMessageRequestHeader = getMessage("RequestHeader");
// 响应体message
export const PBMessageResponse = getMessage("Response");
// 响应状态码
export const ResultCode = getMessage("ResultCode");
// web端入口类型
export const WebEntryType = getMessage("WebEntryType");
// device type
export const DeviceType = getMessage("DeviceType");
// websocket message id
export const WSMsgId = getMessage("WSMsgId");
// product type PRODUCT_TYPE_FCN
export const ProductType = getMessage("ProductType");
// QuotationStatus
export const QuotationStatus = getMessage("QuotationStatus");
// quotation parameter
export const QuotationRecordParam = getMessage("QuotationRecordParam");
// GuaranteedPeriodUnit
export const GuaranteedPeriodUnit = getMessage("GuaranteedPeriodUnit");
// quotation product type
export const QuotationProductType = getMessage("QuotationProductType");
// trade order type
export const TradeOrderType = getMessage("TradeOrderType");
// trade status
export const TradeStatus = getMessage("TradeStatus");
// fee type
export const InstitutionFeeType = getMessage("InstitutionFeeType");
// quotation priority
export const QuotationPriority = getMessage("QuotationPriority");
// individual status
export const IndividualUserStatus = getMessage("IndividualUserStatus");
// individual status
export const NewsPlatformSearchType = getMessage("NewsPlatformSearchType");
// lifecycle log status
export const LifecycleStatus = getMessage("LifecycleStatus");
export const LifecycleEvent = getMessage("LifecycleEvent");
export const LifecycleUnofficialStatus = getMessage("LifecycleUnofficialStatus");
// terminal type
export const TerminalType = getMessage("TerminalType");

export const GoodsTradeModeEnum = getMessage("GoodsTradeModeEnum");
export const GoodsTimeTypeEnum = getMessage("GoodsTimeTypeEnum");
export const ProductStatusEnum = getMessage("ProductStatusEnum");
export const ProductSwitchEnum = getMessage("ProductSwitchEnum");
export const GoodSearchStatusEnum = getMessage("GoodSearchStatusEnum");
export const GoodsOrderByEnum = getMessage("GoodsOrderByEnum");

export const ProductTypePrefix = "PRODUCT_TYPE_";

export const OrderSource = getMessage("OrderSource");
// 个人用户订阅
export const SubscriptionProgram = getMessage("SubscriptionProgram");
// 个人用户工作职能
export const WorkIdentity = getMessage("WorkIdentity");
// 个人用户订阅状态
export const SubscribeStatus = getMessage("SubscribeStatus");
// 续订状态
export const RenewStatus = getMessage('RenewStatus');


